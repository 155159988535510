import React from 'react';
import ReactDOM from 'react-dom';
import { SevenApp } from './SevenApp';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'



import './styles/styles.scss';
const queryClient = new QueryClient();


ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <SevenApp />
  </QueryClientProvider>,


  document.getElementById('root')
);
