



import { fetchConToken, fileForData } from "../helpers/fetch";
import { prepararFechaAbono } from "../helpers/prepararFecha";
import { types } from "../types/types";
import Swal from "sweetalert2";



export const slideStartAddNew = ( slide ) => {
    return async( dispatch ) =>{

        try {
            const resp = await fileForData( 'slideshow/new', slide, 'POST' );
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', 'SlideShow Creado', 'success');
                dispatch( slideAddNew(body.slideshow) );

            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
        } catch (error) {
            console.log(error);
        }
    }
}



export const slideListaStart = () => {
    return async( dispatch ) => {

        try {
            const resp = await fetchConToken(`slideshow`);
            const body = await resp.json();
            if( body.ok ){
                dispatch( slideLista(body.slideshow) );
            }else {
                Swal.fire('Error', body.msg, 'error');
            }                
            
        } catch (error) {
            console.log(error);
        }
    }
}




export const deleteStartSlide = ( uid ) => {
    return async( dispatch ) =>{

        try {
            const resp = await fetchConToken(`slideshow/${ uid }`, {}, 'DELETE');
            const body = await resp.json();

            if( body.ok ) {
                Swal.fire('Exitoso', 'Imagen Eliminada', 'success');
                dispatch( deleteSlideShow(body.slideshow) );

            } else {
                Swal.fire('Error', body.msg, 'error');
            } 
        } catch (error) {
            console.log(error);
        }
    }
}





export const startOpenSlide = ( ) => ({
    type: types.slideModalOpen,
});


export const startCloseSlide = ( ) => ({
    type: types.slideModalClose,
});




const slideLista = ( slide ) => ({
    type: types.slideShowLista,
    payload: slide 
});




const deleteSlideShow = ( slide ) => ({
    type: types.slideShowDelete,
    payload: slide,
});




const slideAddNew = ( slide ) => ({
    type: types.slideAddNew,
    payload: slide
});