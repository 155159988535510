import { AppRouter } from "./routers/AppRouter";
import { store } from "./store/store";
import  { Provider } from 'react-redux';
import { AppTheme } from "./theme";
import { SocketProvider } from "./context/SocketContext";



export const SevenApp = () => {




  return (
    <SocketProvider>
      <AppTheme>
        <Provider store={ store } >
          <AppRouter />
        </Provider>
      </AppTheme>
    </SocketProvider>
    
  )
}
